var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"all_main_wrap"},[_c('HeaderBox',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('a',{on:{"click":function($event){return _vm.$router.push('/admin/Examination/ExamReport')}}},[_vm._v("考试报告")]),_vm._v("/参考人员 ")]},proxy:true}])}),_c('div',{staticClass:"table-box"},[_c('h4',{staticClass:"title_big"},[_c('span',{staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.reportName))])]),_c('a-table',{staticClass:"table-template",attrs:{"rowKey":function (item,index) { return index; },"columns":_vm.columns,"data-source":_vm.tableData,"loading":_vm.loading,"pagination":{
        total: _vm.total,
        current: _vm.queryParams.current,
        defaultPageSize: _vm.queryParams.size,
        showSizeChanger: true,
        showTotal: function (total, range) {
          return ("共" + total + "条");
        },
      }},on:{"change":_vm.onPage},scopedSlots:_vm._u([{key:"index",fn:function(item, row, i){return [_c('div',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s((_vm.queryParams.current - 1) * 10 + i + 1)+" ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }