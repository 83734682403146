<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title>
        <a @click="$router.push('/admin/Examination/ExamReport')"
          >考试报告</a
        >/参考人员
      </template>
    </HeaderBox>
    <!-- 表格列表 -->
    <div class="table-box">
      <h4 class="title_big">
        <span style="margin-right: 10px">{{ reportName }}</span>
      </h4>
      <a-table
        class="table-template"
        :rowKey="(item,index) => index"
        :columns="columns"
        :data-source="tableData"
        :loading="loading"
        @change="onPage"
        :pagination="{
          total: total,
          current: queryParams.current,
          defaultPageSize: queryParams.size,
          showSizeChanger: true,
          showTotal: function (total, range) {
            return `共${total}条`;
          },
        }"
      >
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center;">
            {{ (queryParams.current - 1) * 10 + i + 1 }}
          </div>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "姓名",
    align: "center",
    dataIndex: "name",
  },
  {
    title: "身份证",
    align: "center",
    dataIndex: "idcard",
  },
  {
    title: "联系方式",
    align: "center",
    dataIndex: "mobile",
  },
  {
    title: "考试用时",
    align: "center",
    dataIndex: "duration",
  },
  {
    title: "成绩",
    align: "center",
    dataIndex: "totalScore",
  },
];
import HeaderBox from "@/components/HeaderBox.vue";

export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      columns, // 表头
      tableData: [], // 列表数据
      loading: false, // 列表加载
      total: 0, // 总数量
      queryParams: {
        current: 1, // 页码
        size: 10, // 页数
        signupId: "", // 报名id
      },
      reportName: '',
    };
  },
  // 事件处理器
  methods: {
    // 分页
    onPage(e) {
      this.queryParams.current = e.current;
      this.queryParams.size = e.pageSize;
      this.getManageList();
    },
    // 查询列表
    getManageList() {
      this.loading = true;
      this.$ajax({
        method: "get",
        url: "/hxclass-management/exam/report/examinee/list",
        params: this.queryParams,
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          this.tableData = res.data.records;
          this.total = res.data.total;
        } else {
          this.tableData = [];
          this.total = 0;
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.reportName = this.$route.query.reportName; // 获取考试名称
    this.queryParams.signupId = this.$route.query.signupId; // 获取考试场次
  },
  // 生命周期-实例挂载后调用
  mounted() {
    this.getManageList();
  },
  beforeDestroy() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.table-box {
  /deep/ .ant-tabs-bar {
    border-bottom: none;
  }
}
.title_big {
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: 600;
  color: #000;
}
.table-template {
  margin-top: 0;
}
</style>
